
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import EmailLookupDetailsTable from '@/components/pages/services/emailLookup/shared/EmailLookupDetailsTable.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'EmailLookupHistoryDetailsModal',
  components: { TmButton, TmDropdownDownload, EmailLookupDetailsTable, TmModal },
})
